import { createSlice } from '@reduxjs/toolkit'
import { $getSalesPrice, $addOrderAIDraw, $wechatqyGetKfUrl, $order, $orderConfirm, $paymentWechathyPayment, $paymentQueryStatus, $paymentQueryStatusSync, $stat, $paymentWechatPayNewPageHost, $showAnswer} from "../../api"
import React from "react"


interface initialStateType {
    priceObj:{[priceObj:string]:any}
    isWeChat:boolean
    addOrderObjData:{[priceObj:string]:any}
    productTyle:string
    officialAccountsUrl:string
    userData:{[priceObj:string]:any}
    projectTyleNumber:number
    userTag:{
        tag_1:number
        tag_2:number
        tag_3:number
    }
    projectArr1:string[]
    userInexistence:boolean
    isTags:boolean
    isIphone:boolean
    activityRulesVaule:React.ReactNode
    activityRulesVaule2:React.ReactNode
    activityRulesVaule3:React.ReactNode
    activityRulesVaule4:React.ReactNode
    isrefund:string
    buttonDisablingDuration:number
    orderStatus?:number
    
}
const initialState:initialStateType = {
    priceObj:{},
    isWeChat:false,
    addOrderObjData:{},
    productTyle:'',
    officialAccountsUrl:'',
    userData:{},
    projectTyleNumber:0,
    userTag:{
        tag_1:0,
        tag_2:0,
        tag_3:0
    },
    projectArr1:['attractiveness','attractiveness_v1','attractiveness_v2','attractiveness_v3','attractiveness_v4','attractiveness_v5','playlet','playlet_v2'],
    userInexistence:false,
    isTags:false,
    isIphone:false,
    //ai绘画
    activityRulesVaule:`
    <div>
        <h1 style='text-align:center'>活动规则</h1>
        <p>一、活动时间:即日起至2024年6月30日；</p>
        <p>二、具体规则</p>
        <p>1、凡付费购买参加AI绘画比赛活动的用户，均可参加AI绘画比赛活动并下载获得本次AI绘画比赛图库赛果；</p>
        <p>2、凡付费购买参加AI绘画比赛活动的用户，均可获得一次幸运大转盘机会，有机会抽中iPhone15手机一部（iPhone15手机价格以Apple官方网站公示的价格为准，中奖率1/99999） ；</p>
        <p>3、凡付费购买参加AI绘画比赛活动的用户，均可获得一次幸运大转盘机会，用户可获得随机金额的拼多多购物红包（中奖率99.9/100），领取后可进入拼多多页面直接使用；</p>
        <p>4、用户抽奖后即时开奖，中奖用户页面会在中奖后弹出中奖信息。用户可直接点击弹出的页面，或通过“企微临时会话—历史支付—点击查看AI绘画报告卡片—点击结果页首屏“点击此处抽iPhone15”入口—点击查看抽奖结果”、“进入企微临时会话—点击“测 AI绘画，抽大奖: iPhone15”—点击推送卡片—进入大转盘页面—点击查看抽奖结果”查看中奖信息。</p>
        <p>5、抽中iPhone15手机的用户，可在开奖后的5个自然日内联系客服并提供中奖截图兑奖，待客服核销确认后，将于30个工作日内安排发货。每个用户活动期间限领一次。若开奖后的5个自然日内未联系客服，视为用户自动放弃该兑换该奖品。客服联系方式：点击页面【客服】标志，即可联系客服。</p>
        <p>6、本次抽奖为概率性事件，秉持公正公开原则，禁止任何作弊行为，最终解释权归本司所有；</p>
        <p>7、联系电话：18612609839 </p>
    </div>
    `,
    //抽红包，抽苹果
    /**
     *

二、具体规则








4、用户购买的10集短剧为用户付费后的交付产品，用户可自愿选择参加幸运大转盘，在幸运大转盘获得奖品后如退出活动，可能导致奖品丢失，请在获得奖品后及时兑换或领取，避免损失。

5、用户参加幸运大转盘后，抽中现金红包的用户可在直接弹出页面进行领奖（特别说明：若抽中现金红包需自主填写手机号或联系在线客服进行领奖）。抽中拼多多购物抵扣券的用户，可在页面直接跳转领取拼多多购物抵扣券，进入拼多多产品中使用。
     */
    activityRulesVaule2:`
    <div>
        <h1 style='text-align:center'>活动规则</h1>
        <p>一、活动时间:即日起至 2024 年 6 月 30 日；</p>
        <p>二、具体规则</p>
        <p>1、凡付费购买看短剧的用户，均可在付费后的结果页（继续看剧入口）观看 10 集短剧；</p>
        <p>2、凡付费购买看短剧的用户，均限时赠送一次幸运大转盘机会，赠送的幸运大转盘，用户可自主自愿选择参加，用户亦可选择不参加；
        3、如自愿免费参加幸运大转盘活动的用户，有机会获得以下惊喜：
        惊喜一：88 元至 2888 元不等的现金红包，中奖概率 1/99999;
        惊喜二：0.01 元至 20 元不等的拼多多购物抵扣券，中奖概率 99998/99999；
        </p>
        <p>4、用户购买的10集短剧为用户付费后的交付产品，用户可自愿选择参加幸运大转盘，在幸运大转盘获得奖品后如退出活动，可能导致奖品丢失，请在获得奖品后及时兑换或领取，避免损失。</p>
        <p>5、用户参加幸运大转盘后，抽中现金红包的用户可在直接弹出页面进行领奖（特别说明：若抽中现金红包需自主填写手机号或联系在线客服进行领奖）。抽中拼多多购物抵扣券的用户，可在页面直接跳转领取拼多多购物抵扣券，进入拼多多产品中使用。</p>
    </div>
    `,
    //抽华为
    activityRulesVaule3:`
    <div>
        <h1 style='text-align:center'>活动规则</h1>
        <p>一、活动时间:即日起至2024年6月30日；</p>
        <p>二、具体规则</p>
        <p>1、凡付费购买参加猜剧情抽红包、猜剧情抽手机的用户，均可参加猜剧情抽红包、猜剧情抽手机活动并可观看已解锁剧集；</p>
        <p>2、凡付费购买参加猜剧情抽红包、猜剧情抽手机的用户，均可获得一次幸运大转盘机会，有机会抽中如下奖品：
        【奖品以及中奖概率说明】
        惊喜一：888元现金红包，中奖概率1/99999;
        惊喜二：2888元现金红包，中奖概率1/99999;
        惊喜三：1888元现金红包，中奖概率1/99999;
        惊喜四：华为 mate 60手机一部，中奖概率1/99999，（华为 mate 60手机价格以华为官方网站公式的价格为准）；
        惊喜五：随机购物红包中奖概率99.9%;</p>
        <p>3、本活动为用户自愿参与，在活动中获得奖品后，如退出活动，可能导致奖品丢失，请在获得奖品后及时兑换或领取，避免损失。</p>
        <p>4、用户抽奖后即时开奖，中奖用户页面会在中奖后弹出中奖信息。用户可直接点击弹出的页面，或通过“企微临时会话—历史支付—点击查看猜剧情抽红包卡片—点击结果页首屏 “点击此处抽红包”入口—点击查看抽奖结果”、“进入企微临时会话—点击“猜剧情抽888元红包”—点击推送卡片—进入大转盘页面—点击查看抽奖结果”查看中奖信息。</p>
        <p>5、用户抽奖后即时开奖，中奖用户页面会在中奖后弹出中奖信息。用户可直接点击弹出的页面，或通过“企微临时会话—历史支付—点击查看猜剧情抽手机卡片—点击结果页首屏 “点击此处抽手机”入口—点击查看抽奖结果”、“进入企微临时会话—点击“猜剧情抽手机”—点击推送卡片—进入大转盘页面—点击查看抽奖结果”查看中奖信息。</p>
        <p>6、参与活动用户的设备不同，参与步骤可能存在不同。参与活动的具体操作步骤请以进入活动页面时的实时展示为准；</p>
        <p>7、红包、手机等奖品将在中奖后的5个自然日内核销并充值进用户填写的账号，敬请认真、准确填写领取账号，因个人原因填写错误，导致充值失败的，将不给予补发。用户中奖后需第一时间联系客服核销。若开奖后的5个自然日内未联系客服，视为用户自动放弃该兑换该奖品。客服联系方式：点击页面【客服】标志，即可联系客服。</p>
        <p>8、本次抽奖为概率性事件，秉持公正公开原则，禁止任何作弊行为，最终解释权归本司所有；</p>
        <p>9、联系电话：18612609839 </p>
    </div>
    `,
    //抽OPPO
    activityRulesVaule4:`
    <div>
        <h1 style='text-align:center'>活动规则</h1>
        <p>一、活动时间:即日起至2024年6月30日；</p>
        <p>二、具体规则</p>
        <p>1、凡付费购买参加猜剧情抽红包、猜剧情抽手机的用户，均可参加猜剧情抽红包、猜剧情抽手机活动并可观看已解锁剧集；</p>
        <p>2、凡付费购买参加猜剧情抽红包、猜剧情抽手机的用户，均可获得一次幸运大转盘机会，有机会抽中如下奖品：
        【奖品以及中奖概率说明】
        惊喜一：888元现金红包，中奖概率1/99999; 
        惊喜二：2888元现金红包，中奖概率1/99999;
        惊喜三：1888元现金红包，中奖概率1/99999; 
        惊喜四：OPPO Find N3 Flip手机一部，中奖概率1/99999，（OPPO Find N3 Flip手机价格以OPPO官方网站公式的价格为准）； 
        惊喜五：随机购物红包中奖概率99.9%;</p>
        <p>3、本活动为用户自愿参与，在活动中获得奖品后，如退出活动，可能导致奖品丢失，请在获得奖品后及时兑换或领取，避免损失。</p>
        <p>4、用户抽奖后即时开奖，中奖用户页面会在中奖后弹出中奖信息。用户可直接点击弹出的页面，或通过“企微临时会话—历史支付—点击查看猜剧情抽红包卡片—点击结果页首屏 “点击此处抽红包”入口—点击查看抽奖结果”、“进入企微临时会话—点击“猜剧情抽888元红包”—点击推送卡片—进入大转盘页面—点击查看抽奖结果”查看中奖信息。</p>
        <p>5、用户抽奖后即时开奖，中奖用户页面会在中奖后弹出中奖信息。用户可直接点击弹出的页面，或通过“企微临时会话—历史支付—点击查看猜剧情抽手机卡片—点击结果页首屏 “点击此处抽手机”入口—点击查看抽奖结果”、“进入企微临时会话—点击“猜剧情抽手机”—点击推送卡片—进入大转盘页面—点击查看抽奖结果”查看中奖信息。</p>
        <p>6、参与活动用户的设备不同，参与步骤可能存在不同。参与活动的具体操作步骤请以进入活动页面时的实时展示为准；</p>
        <p>7、红包、手机等奖品将在中奖后的5个自然日内核销并充值进用户填写的账号，敬请认真、准确填写领取账号，因个人原因填写错误，导致充值失败的，将不给予补发。用户中奖后需第一时间联系客服核销。若开奖后的5个自然日内未联系客服，视为用户自动放弃该兑换该奖品。客服联系方式：点击页面【客服】标志，即可联系客服。</p>
        <p>8、本次抽奖为概率性事件，秉持公正公开原则，禁止任何作弊行为，最终解释权归本司所有；</p>
        <p>9、联系电话：18612609839 </p>
    </div>
    `,
    isrefund:'',
    buttonDisablingDuration:5000,//支付按钮禁用时长
    orderStatus:0
}

const commonMethodsReducer = createSlice({
    name:'commonMethodsReducer',
    initialState,
    reducers:{
        stopScroll(){//禁止滚动
            // console.log('禁止滚动')
            // var mo=function(e){e.preventDefault();};
            document.body.style.overflow='hidden';
            // document.addEventListener("touchmove",mo,false);//禁止页面滑动
        },
        
 
        canScroll(){/***允许滚动***/
            // var mo=function(e){e.preventDefault();};
            document.body.style.overflow='';//出现滚动条
            // document.removeEventListener("touchmove",mo,false);
        },

        stateReadIn (state,actiion) {
            // state.priceObj = actiion.payload.res
            // actiion.payload.variateName
            // console.log(actiion.payload.variateName)
            switch (actiion.payload.variateName) {
                case 'priceObj':
                    state.priceObj = actiion.payload.res
                    break;
                case 'addOrderObjData':
                    state.addOrderObjData = actiion.payload.res
                    break;
                case 'wechatqyGetKfUrlData':
                    state.officialAccountsUrl = actiion.payload.res
                    break;
                case 'orderStatus':
                    state.orderStatus = actiion.payload.res
                    break;
                case 'userData':
                    if (actiion.payload.res) {
                        state.userInexistence = true
                        state.userData = actiion.payload.res
                        state.projectTyleNumber = actiion.payload.res.order_type
                        state.userTag.tag_1 =  actiion.payload.res.tag_list === undefined ? 0 :  actiion.payload.res.tag_list.tag_1 
                        state.userTag.tag_2 = actiion.payload.res.tag_list === undefined ? 0 :actiion.payload.res.tag_list.tag_2
                        state.userTag.tag_3 = actiion.payload.res.tag_list === undefined ? 0 :actiion.payload.res.tag_list.tag_3
                        state.isTags = actiion.payload.res.is_set_tags
                        state.isIphone = actiion.payload.res.is_has_bonus
                        state.isrefund = actiion.payload.res.order_status
                    }else {
                        state.userInexistence = actiion.payload.res
                    }
                    break;
            
                default:
                    break;
            }
        },

        getProductTypeMethods (state) {
            // console.log('loction',window.location.pathname.split('/')[1])
            state.productTyle = window.location.pathname.split('/')[1]
        },

        isWeChatMethods(state){
            var ua = window.navigator.userAgent.toLowerCase();
            if (ua.match(/MicroMessenger/i) === null) {
                state.isWeChat = false;
            } else {
                state.isWeChat = true;
            }
        }
  
    }
})



export const { stopScroll, canScroll, stateReadIn, getProductTypeMethods, isWeChatMethods } = commonMethodsReducer.actions


// 数据请求

export const getPriceMethod = (payload:string):any => {
    // console.log('获取参数',payload)
    return ( dispatch:any, getState:any) => {
        $getSalesPrice({fromk:payload,page_path:window.location.pathname.split('/')[1]}).then((res)=> {
            let res_ = res as {[reaType:string]:any}
            dispatch(stateReadIn({res:res_.data.data[0],variateName:'priceObj'}))
                // console.log('价格',res_.data.data[0])
        })
    }
}

export const isShowAnswerImmediate = (payload:string):any => {
    // console.log('获取参数',payload)
    return ( dispatch:any, getState:any) => {
        $showAnswer({order_id:payload}).then((resData)=>{
            let res =  resData as {[resData:string]:any}
            if ( res.data.data.show_answer) {
                window.location.replace(`/attractiveness/payment/succeed/result/${payload}${window.location.search}`)
            }else{
                window.location.replace(`/attractiveness/payment/succeed/${payload}${window.location.search}`)
            }
        })
    }
}



interface addOrderAIDrawTyle {
    is_measure:number| string
    fromk: string
    pageUrl: string
    openid?:string
}
export const addOrderAIDrawSbumitMethod = (payload:addOrderAIDrawTyle):any => {
    return ( dispatch:any,getState:any) => {
        $addOrderAIDraw(payload).then(( resObj ) => {
            let res = resObj as {[resObjTyle:string]:any}
            if ( res.status === 200){
                // $stat(`${getState().commonMethodsReducer.productTyle}_index_sbumit`)
                sessionStorage.setItem('userId',res.data.data.id)
                localStorage.removeItem('redpacketType')  
                window.localStorage.removeItem('discount_id') 
                window.localStorage.removeItem('discount_type')
                dispatch(stateReadIn({res:res.data.data,variateName:'addOrderObjData'}))
            }
            // console.log('res',res)
        })
    }
}

export const getOfficialAccountsLink = (payload:string):any => {
    return ( dispatch:any ) => {
        $wechatqyGetKfUrl(payload).then(( resObj ) => {
            let res = resObj as {[resObjTyle:string]:any}
            dispatch(stateReadIn({res:res.data.data.url,variateName:'wechatqyGetKfUrlData'}))
        }).catch( ( err ) => {
            console.log('err',err)
        })
    }
}

export const getUserInfoMethods = (payload:string):any => {
    return ( dispatch:any ) => {
        $order(payload).then(( resObj ) => {
            let res = resObj as {[resObjTyle:string]:any}
            dispatch(stateReadIn({res:res.data.data,variateName:'userData'}))
        }).catch(( err ) => {
            console.log('err',err)
            dispatch(stateReadIn({res:false,variateName:'userData'}))
        })
    }
}

interface orderConfirmType {
    order_id:string
    paytype:string
    discount_id?:number
    discount_type?:string
}
declare const window: any;
export const payment = (payload:orderConfirmType):any => {
    return ( dispatch:any,getState:any ) => {
        $stat(`${getState().commonMethodsReducer.productTyle}_pattern_payment_${payload.paytype}`)
        $paymentQueryStatusSync({order_id:payload.order_id}).then(( querData ) => {
            let querRes = querData as  {[resObjTyle:string]:any}
            // 100 订单未确认
            // 150 已确认
            // 300 支付完成
            // console.log('querRes',querRes.data.data.order_status)
            if ( querRes.data.data.order_status === 300 ) {
                // window.localStorage.clear()
                // window.localStorage.removeItem('userId')
                // window.localStorage.removeItem('redpacketType')  
                // window.localStorage.removeItem('discount_id') 
                // window.localStorage.removeItem('discount_type')
                if ( getState().commonMethodsReducer.projectArr1.indexOf(window.location.pathname.split('/')[1]) !== -1 ) {
                    // window.location.replace(`/attractiveness/payment/succeed/${payload.order_id}${window.location.search}`)
                    // isShowAnswerImmediate
                    dispatch(isShowAnswerImmediate(payload.order_id))
                }
            }
            else{
                $orderConfirm(payload).then((orderConfirmData)=> {
                    let confirmRes = orderConfirmData as  {[resObjTyle:string]:any}
                    if( confirmRes.status === 200 ){
                        if (getState().commonMethodsReducer.isWeChat ) {
                            // console.log('支付',getState().commonMethodsReducer.productTyle)

                         
                            const paymentCallback = () => {
                                $paymentWechathyPayment(payload.order_id,window.location.pathname.split('/')[1]).then(( resData ) => {
                                    let payParamsRes = resData as {[resData:string]:any}
                                    // console.log('支付参数',payParamsRes.data.data)
                                    
                                    window.WeixinJSBridge.invoke('getBrandWCPayRequest',{
                                        "appId":payParamsRes.data.data.appId,
                                        "timeStamp":payParamsRes.data.data.timeStamp,
                                        "nonceStr":payParamsRes.data.data.nonceStr,  
                                        "package":payParamsRes.data.data.package, 
                                        "signType":payParamsRes.data.data.signType,
                                        "paySign":payParamsRes.data.data.paySign
                                    },
                                    function(res:any) {
                                        // console.log('支付状态',res)
                                        if (res.err_msg === "get_brand_wcpay_request:ok") {
                                            // 使用以上方式判断前端返回,微信团队郑重提示：
                                            //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                                            window.location.replace(`/attractiveness/payment/succeed/${payload.order_id}${window.location.search}`)
                                        }
                                    }
                                    )
                                    
                                })
                            }
                           
                            $paymentWechatPayNewPageHost({order_id:payload.order_id}).then((paymentWechatPayNewPageHostRes) => {
                                let res = paymentWechatPayNewPageHostRes as {[resData:string]:any}
                                // console.log('res',res.data.data)
                                // console.log('res',getState().commonMethodsReducer.productTyle)
                                if ( getState().commonMethodsReducer.productTyle !== 'common_payment' ) {
                                    // res.data.data.is_new_page 值： 0 跳转空白页； 1 是跳转第三方链接支付
                                    switch (res.data.data.is_new_page) {
                                        case 0:
                                            window.location.href=`https://${res.data.data.new_page_host}/common_payment/${payload.order_id}${window.location.search}`
                                            break;
                                        case 1:
                                            window.location.href = `/api/v1/payment/wechathy/${payload.order_id}/${window.location.pathname.split('/')[1]}/wechat`
                                            break;
                                        default:    
                                            paymentCallback()
                                            break;
                                    }
                                    // if (res.data.data.is_new_page) {
                                    // }else{
                                    //     paymentCallback()
                                    // }
                                }else{
                                    paymentCallback()
                                }
                            })


                          
                        }else{
                            //alipay
                            //wechathy
                            // console.log('iceObj',getState().commonMethodsReducer.priceObj.wechat_switch)
                            if ( getState().commonMethodsReducer.priceObj.wechat_switch ){
                                if ( payload.paytype === '1' ) { 
                                    // window.location.href = `https://lily.origv.com/api/v1/payment/${payload.paytype === '1'?'alipay':'wechathy'}/${payload.order_id}/${window.location.pathname.split('/')[1]}`
                                    window.location.href = `/api/v1/payment/${payload.paytype === '1'?'alipay':'wechathy'}/${payload.order_id}/${window.location.pathname.split('/')[1]}`
                                }else{
                                    if (getState().commonMethodsReducer.priceObj.qywx_switch === 1){
                                        $wechatqyGetKfUrl(payload.order_id).then(( resObj ) => {
                                            let res = resObj as {[resObjTyle:string]:any}
                                            window.location.href = res.data.data.url
                                        })
                                    }else{
                                        window.location.href = `/api/v1/payment/${payload.paytype === '1'?'alipay':'wechathy'}/${payload.order_id}/${window.location.pathname.split('/')[1]}`
                                        // if ( getState().commonMethodsReducer.productTyle === "playlet") {
                                        //     window.location.href = `/payment_transition${window.location.search}`
                                        //     // console.log(`/payment_transition${window.location.search}`,getState().commonMethodsReducer.productTyle)
                                        // }else{
                                        //     window.location.href = `/api/v1/payment/${payload.paytype === '1'?'alipay':'wechathy'}/${payload.order_id}/${window.location.pathname.split('/')[1]}`
                                        // }
                                        
                                        
                                            
                                        // if (getState().commonMethodsReducer.priceObj.xcxid) { //小程序
                                        //     $getXcxUrl(payload.order_id,getState().commonMethodsReducer.priceObj.fromk).then((res) => {
                                        //         let resObj = res as {[resObjTyle:string]:any}
                                        //         window.location.href = resObj.data.data.url
                                        //     })
                                        // }else{
                                        //     // window.location.href = `https://lily.origv.com/api/v1/payment/${payload.paytype === '1'?'alipay':'wechathy'}/${payload.order_id}/${window.location.pathname.split('/')[1]}`
                                        //     window.location.href = `/api/v1/payment/${payload.paytype === '1'?'alipay':'wechathy'}/${payload.order_id}/${window.location.pathname.split('/')[1]}`
                                        // }
                                    }
                                }
                            }else{
                                window.location.href = `/api/v1/payment/alipay/${payload.order_id}/${window.location.pathname.split('/')[1]}`
                            }
                        }
                    }
                })
            }
        })
    }
}

export const isPaymentSucceed = (payload:string):any => {
    return ( dispatch:any, getState:any,setState:any ) => {
        $paymentQueryStatus({order_id:payload}).then(( querData ) => {
            let querRes = querData as  {[resObjTyle:string]:any}
             // 100 订单未确认
            // 150 已确认
            // 300 支付完成
            window.sessionStorage.setItem('statePayment',querRes.data.data.order_status)
            dispatch(stateReadIn({res:querRes.data.data.order_status,variateName:'orderStatus'}))
            if ( querRes.data.data.order_status === 300 ){
                // window.localStorage.clear()
                // window.localStorage.removeItem('userId')
                // window.localStorage.removeItem('redpacketType')  
                // window.localStorage.removeItem('discount_id') 
                // window.localStorage.removeItem('discount_type')
                if ( getState().commonMethodsReducer.projectArr1.indexOf(window.location.pathname.split('/')[1]) !== -1 ) {
                    // window.location.replace(`/attractiveness/payment/succeed/${payload}${window.location.search}`)
                    dispatch(isShowAnswerImmediate(payload))
                }
            }
        })
    }
}



// interface addOrderParamsType {
//     order_type:number | string
//     birth_state:number
//     pub_birth_date:string
//     name:string
//     xname:string
//     fromk:string| null
//     yl:number
//     pageUrl:string
//     sex:string | number
//     openid:string| null
//     mch_id:string| null
//     kf:string| null
//     parent_order_id:string| null
// }
// export const userSubmitInfoMethods = (payload:addOrderParamsType):any => {
//     return ( dispatch:any, getState:any) => {
//         let checkWordData_ = {
//             word:payload.name,
//             from:payload.fromk
//         }
//         $checkWord(checkWordData_).then(( res ) => {
//             let res_ = res as {[res:string]:any}
//             console.log('res',res_.data.data.pass)
//             if ( res_.data.data.pass ) {
//                 $addOrder(payload).then(( addOrderRes )=> {
//                     let addOrderResDataCopy = addOrderRes as {[addOrderRes:string]:any}
//                     if( addOrderResDataCopy.status === 200 ) {
//                         // console.log('提交信息接口$addOrder:',addOrderResDataCopy)
//                         window.location.href=`/${getState().commonMethodsReducer.projectLogoUrl}/payment/${addOrderResDataCopy.data.data.id}/10?from=${addOrderResDataCopy.data.data.fromk}`;
//                     }
//                 }).catch(( err ) => {
//                     Toast.show({
//                         content: err.response.data.message,
//                     })
//                     // console.log('addOrderErr',err.response.data.message)
//                 })
//             }else{
//                 Toast.show({
//                     content: '无法为您提供测算服务，还请修改。',
//                 })
//             }
//         })
//     }
// }


// export const getUserInfoMethods = ( payload:string ):any => {
//     return ( dispatch:any, getState:any ) => {
//         $order(payload).then(( resolve ) => {
//             let res = resolve as {[resolve:string]:any}
//             if (getState().commonMethodsReducer.userInfo === null) {
//                 dispatch(setStateDataMethod(res.data.data))
//             }
//         })

//     }
// }


export default commonMethodsReducer.reducer